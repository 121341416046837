<template>
  <div>
    <vs-alert
      :active.sync="showDismissibleAlert"
      closable
      icon-pack="feather"
      close-icon="icon-x"
      color="danger"
      class="p-1"
      style="min-height: 70px"
      >{{ message }}
    </vs-alert>
    <br />
    <span class="form-label">Email</span>
    <form method="post" id="login-form" @submit="checkForm">
      <vs-input
        :danger="errors.first('email') ? true : false"
        :success="!errors.first('email') && user.email != ''"
        val-icon-success="done"
        val-icon-danger="error"
        v-validate="'required|email|min:3'"
        data-vv-validate-on="blur"
        name="email"
        icon-no-border
        icon="icon icon-user"
        icon-pack="feather"
        label-placeholder=""
        v-model="user.email"
        class="w-full text-base"
        @keyup="validateInput"
      />
      <span class="text-danger text-sm mb-2">{{ errors.first("email") }}</span>
      <br />

      <span class="form-label">Password</span>
      <vs-input
        :danger="errors.first('password') ? true : false"
        :success="!errors.first('password') && user.password != ''"
        val-icon-success="done"
        val-icon-danger="error"
        data-vv-validate-on="blur"
        v-validate="{ required: true }"
        name="password"
        icon-no-border
        icon-pack="feather"
        label-placeholder=""
        v-model="user.password"
        autocomplete="new-password"
        class="w-full text-base"
        :icon="passwordIcon"
        :type="passwordType"
        @click.native="showHidePassword($event)"
        @keyup="validateInput"
      >
        <template slot="icon" slot-scope="data">
          <i
            v-if="!data.showPassword"
            class="
              vs-icon
              notranslate
              icon-scale icon-inputx
              notranslate
              vs-input--icon
              feather
              icon icon-eye
              null
              icon-no-border
            "
          ></i>
          <i
            v-if="!showPassword"
            class="
              vs-icon
              notranslate
              icon-scale icon-inputx
              notranslate
              vs-input--icon
              feather
              icon icon-eye
              null
              icon-no-border
            "
          ></i>
        </template>
      </vs-input>
      <span class="text-danger text-sm">{{ errors.first("password") }}</span>
    </form>

    <div class="flex flex-wrap justify-between mt-3 mb-8">
      <vs-checkbox v-model="rememberMe" name="rememberMe" class="text-base"
        >Remember Me</vs-checkbox
      >
      <a class="cursor-pointer text-base" @click="forgotPassword">Forgot Password?</a>
    </div>

    <div>
      <vs-button
        class="w-full"
        form="login-form"
        button="submit"
        :disabled="!validateForm"
        >Login</vs-button
      >
    </div>
    <div class="mt-3 text-center mb-1">
      <span class="text-base"> New to Fresh? </span>
      <a class="cursor-pointer text-base" href="https://www.freshclinics.com/en-au/join-fresh-clinics" target="_blank">Join here</a>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
// For custom error message
import { Validator } from "vee-validate";
import { ACTION_UPDATE_POPUP_FLAG } from "../../../store/types/auth-type";
const dict = {
  custom: {
    email: {
      required: "Please enter your email address",
    },
    password: {
      required: "Please enter your password",
    },
  },
};

// register custom messages
Validator.localize("en", dict);

export default {
  data() {
    return {
      user: {
        email: "",
        password: "",
      },
      rememberMe: false,
      loginStatus: false,
      showDismissibleAlert: false,
      message: "",
      showPassword: false,
      passwordType: "password",
      passwordIcon: "icon icon-eye-off",
      showGoToButton: process.env.VUE_APP_REGION !== "US" ? true : false,
      userId: null,
      secret: null,
    };
  },
  computed: {
    validateForm() {
      this.$vs.loading.close();
      return !this.errors.any() && this.email != "" && this.password != "";
    },
  },
  created() {
    const url = new URL(window.location.href);
    if (url.searchParams.get("userId")) {
      let payload = {
        rememberMe: false,
        userDetails: {
          email: "demo@email.com",
          password: "demo",
        },
        userId: url.searchParams.get("userId"),
        secret: url.searchParams.get("secret"),
      };
      if(url.searchParams.get("source")){
        payload = {
          ...payload,
          iframe_view: {
            sidebar: false,
            userDropdown: false,
            footer: false,
            closeCta: true,
          },
          target: url.searchParams.get("target") || null,
          organizationId: url.searchParams.get("organizationId") || null,
          clinicId: (url.searchParams.get("clinicId")) || null,
          orderId: (url.searchParams.get("orderId")) || null,
          productId: (url.searchParams.get("productId")) || null,
        }
      }
      this.$store
        .dispatch("auth/loginJWT", payload)
        .then((response) => {
          this.$vs.loading.close();
          // TO DO
          // this.analytics.identify(response.data.data.user.id, {
          //   name: response.data.data.user.name,
          //   email: response.data.data.user.email,
          // });
        })
        .catch((error) => {
          this.showDismissibleAlert = true;
          this.message = error.message;
          this.$vs.loading.close();
          /*this.$vs.notify({
                  title: 'Error',
                  text: error.message,
                  iconPack: 'feather',
                  icon: 'icon-alert-circle',
                  color: 'danger'
                })*/
        });
    }
  },
  methods: {
    ...mapActions("users", ["login"]),
    ...mapActions("auth", {
      updatePopupFlag: ACTION_UPDATE_POPUP_FLAG,
    }),
    validateInput(event) {
      this.$validator.validateAll();
    },
    checkForm(event) {
      event.preventDefault();
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.loginJWT();
        }
      });
    },
    showHidePassword(event) {
      if (
        event.target.className ===
          "vs-icon notranslate icon-scale icon-inputx notranslate vs-input--icon feather icon icon-eye-off null icon-no-border" ||
        event.target.className ===
          "vs-icon notranslate icon-scale icon-inputx notranslate vs-input--icon feather icon icon-eye null icon-no-border"
      ) {
        this.showPassword = !this.showPassword;
        this.passwordType === "password"
          ? (this.passwordType = "text")
          : (this.passwordType = "password");
        this.passwordIcon === "icon icon-eye-off"
          ? (this.passwordIcon = "icon icon-eye")
          : (this.passwordIcon = "icon icon-eye-off");
      }
      return true;
    },
    loginJWT() {
      const payload = {
        rememberMe: this.rememberMe,
        userDetails: this.user,
      };

      this.$validator
        .validateAll()
        .then((result) => {
          if (result) {
            this.$vs.loading();
            this.updatePopupFlag(true);
            this.$store
              .dispatch("auth/loginJWT", payload)
              .then((response) => {
                this.$vs.loading.close();
                // TO DO
                // this.analytics.identify(response.data.data.user.id, {
                //   name: response.data.data.user.name,
                //   email: response.data.data.user.email,
                // });
              })
              .catch((error) => {
                this.showDismissibleAlert = true;
                this.message = error.message;
                console.log("error", error);

                this.$vs.loading.close();
                /*this.$vs.notify({
                  title: 'Error',
                  text: error.message,
                  iconPack: 'feather',
                  icon: 'icon-alert-circle',
                  color: 'danger'
                })*/
              });
          }
        })
        .catch((error) => {
          this.showDismissibleAlert = true;
          this.message =
            error && error.message ? error.message : "Server Error";
          this.$vs.loading.close();
        });
    },
    forgotPassword() {
      this.displayLoader();
      this.$router.push("/forgot-password");
    },
    register() {
      this.displayLoader();
      this.$router.push("/register");
    },
    order() {
      window.location.href = "https://orders.freshclinics.com.au/wp-login.php";
    },
    displayLoader() {
      this.$vs.loading();
      setTimeout(() => {
        this.$vs.loading.close();
      }, 1000);
    },
    checkLogin() {
      // If user is already logged in notify
      if (this.$store.state.auth.isUserLoggedIn()) {
        // Close animation if passed as payload
        this.$vs.loading.close();

        this.$vs.notify({
          title: "Login Attempt",
          text: "You are already logged in!",
          color: "warning",
        });

        return false;
      }
      return true;
    },
    // loginJWT() {
    //
    //   if (!this.checkLogin()) return
    //
    //   // Loading
    //   this.$vs.loading()
    //
    //   const payload = {
    //     checkbox_remember_me: this.checkbox_remember_me,
    //     userDetails: {
    //       email: this.email,
    //       password: this.password
    //     }
    //   }
    //
    //   this.$store.dispatch('auth/loginJWT', payload)
    //     .then(() => { this.$vs.loading.close() })
    //     .catch(error => {
    //       this.$vs.loading.close()
    //       this.$vs.notify({
    //         title: 'Error',
    //         text: error.message,
    //         iconPack: 'feather',
    //         icon: 'icon-alert-circle',
    //         color: 'danger'
    //       })
    //     })
    // },
    registerUser() {
      if (!this.checkLogin()) return;
      this.$router.push("/register").catch(() => {});
    },
  },
};
</script>


<style>
  .form-label {
    font-size: 12px;
    line-height: 18px;
    font-weight: 400;
  }
  .vs-input--input,
  .vs-input--input.normal {
    font-size: 16px !important;
  }
</style>
